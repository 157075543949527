import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import Carousel, { NextArrow, PrevArrow } from 'components/organisms/Carousel';
import CustomModal from 'components/organisms/Modal';

interface PopupImageProps {
  open?: boolean;
  listImage: string[];
  handleClose?: () => void;
  activeIdx: number;
}

const PopupImage: React.FC<PopupImageProps> = ({
  open,
  listImage,
  handleClose,
  activeIdx,
}) => {
  const [nav, setNav] = useState<Slider | null>();
  const [slideActive, setSlideActive] = useState(1);
  const setting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow customArrow="white" />,
    nextArrow: <NextArrow customArrow="white" />,
    infinite: false,
    cssEase: 'ease-in-out',
    afterChange: (next: number) => {
      setSlideActive(next + 1);
    },
  };

  useEffect(() => {
    if (nav) {
      setSlideActive(activeIdx + 1);
      nav?.slickGoTo(activeIdx);
    }
  }, [nav, activeIdx]);

  return (
    <div className="popupImage">
      <CustomModal variant="image" isOpen={open || false} handleClose={handleClose} showIconClose>
        <div className="popupImage_carousel">
          <Carousel settings={setting} ref={(slider) => setNav(slider)}>
            {
              listImage?.map((item, index) => (
                <div key={`carousel-image-${index.toString()}`} className="popupImage_carousel-item">
                  <Image imgSrc={item} alt="carousel-image" size="contain" ratio="375x498" />
                </div>
              ))
            }
          </Carousel>
          <div className="popupImage_slide">
            <Text modifiers={['16x24', '600', 'white']}>
              {slideActive}
              /
              {listImage?.length}
            </Text>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default PopupImage;
