import dayjs from 'dayjs';
import React, { useState, useMemo, useEffect } from 'react';
import {
  useForm,
  FormProvider,
  Controller,
} from 'react-hook-form';
import {
  useQuery,
  useMutation,
} from 'react-query';
import { useParams } from 'react-router-dom';

import PopupImage from './popupImage';

import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import Loading from 'components/atoms/Loading';
import Text from 'components/atoms/Text';
import TextArea from 'components/atoms/TextArea';
import ProcessTitle from 'components/molecules/ProcessTitle';
import SectionTitle from 'components/molecules/SectionTitle';
import Signature from 'components/molecules/Signature';
import InfoCard from 'components/organisms/InfoCard';
import CustomModal from 'components/organisms/Modal';
import Table from 'components/organisms/Table';
import NotifyModal from 'components/templates/NotifyModal';
import PageLayout from 'components/templates/PageLayout';
import PopupTable from 'components/templates/PopupTable';
import {
  getEreportServices,
  postEreportServices,
} from 'services/eReport';
import { renderNumber } from 'utils/functions';

const STATUS = {
  NOT_APPROVE_YET: 0,
  APPROVE: 1,
  NOT_APPROVE: 2,
};

const dataHeaderReportTable = [
  { field: 'plan', headerName: 'SL kế hoạch' },
  // { field: 'quantity', headerName: 'SL đầu ca' },
  { field: 'actual', headerName: 'SL thực tế' },
  // { field: 'kpi', headerName: 'KPI' },
];

const dataHeaderReportKPITable = [
  { field: 'product', headerName: 'Sản phẩm' },
  { field: 'amount', headerName: 'SL(thùng / két)' },
  { field: 'unit', headerName: 'SL (lon/chai)' },
  { field: 'kpi', headerName: 'KPI' },
];

type ReportFormType = {
  note: string;
  file: string;
};

type PopupHeader = {
  field: string;
  headerName: string;
};

type PopupData = {
  product?: string;
  plan?: number;
  actual?: number;
};

type PopupType = 'gift';

const Home: React.FC = () => {
  const [popupType, setPopupType] = useState<PopupType | ''>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [reportStatus, setReportStatus] = useState<number>(0);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [note, setNote] = useState('');
  const [isOpenImgs, setIsOpenImgs] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [fileError, setFileError] = useState('');
  const [errorModal, setErrorModal] = useState({
    title: '',
    open: false,
    message: '',
  });
  const [activeIdx, setActiveIdx] = useState<number>(0);

  const { slug: token, key } = useParams();

  const method = useForm<ReportFormType>({
    mode: 'onChange',
    defaultValues: {
      note: '',
      file: '',
    },
  });

  const watchFile = method.watch('file');

  const { data: eReportRes, isFetching } = useQuery(
    ['getEreportServices', token, key],
    () => getEreportServices({
      token: token || '',
      key: key || '',
    }),
    {
      enabled: !!token,
      onError: (err) => {
        if (Array.isArray(err) && err.length > 0) {
          if (err[0].code === 404) {
            setIsNotFound(true);
          }
        }
      }
    },
  );

  const infoCard = useMemo(() => {
    if (eReportRes) {
      return [
        {
          title: 'Tên chương trình',
          description: eReportRes?.brand?.name,
        },
        {
          title: 'Tỉnh/Thành',
          description: eReportRes?.province?.name,
        },
        {
          title: 'Tên Outlet',
          description: eReportRes?.outlet?.name,
        },
        {
          title: 'Mã Outlet',
          description: eReportRes?.outlet?.code,
        },
        {
          title: 'Địa chỉ',
          description: eReportRes?.outlet?.address,
        },
        {
          title: 'Thời gian thực hiện',
          description: dayjs(eReportRes?.time?.start).format('DD-MM-YYYY HH:mm'),
        },
        {
          title: 'Đơn vị thực hiện',
          description: eReportRes?.implementing_agencies,
        },
      ];
    }
    return [];
  }, [eReportRes]);

  const dataTableKPI = useMemo(() => {
    if (eReportRes && eReportRes.sku_report.length > 0) {
      return eReportRes.sku_report.map((item) => ({
        product: item.name,
        amount: renderNumber(item.carton_safe),
        unit: renderNumber(item.bottle_can),
        kpi: renderNumber(item.kpi),
      }));
    }
    return [];
  }, [eReportRes]);

  const dataPopup = useMemo(() => {
    let header: PopupHeader[] = [];
    let data: PopupData[] = [];
    let comment = '';
    if (eReportRes) {
      header = [
        { field: 'product', headerName: 'I. QUÀ TẶNG (ĐVT)' },
        ...dataHeaderReportTable
      ];
      data = eReportRes?.gift_report?.items?.map((item) => ({
        product: item.name,
        plan: item.kpi,
        // quantity: item.quantity,
        actual: item.quantity_used,
      })) || [];
      comment = eReportRes?.gift_report?.comment || '';
    }
    return {
      header,
      data,
      comment,
    };
  }, [eReportRes]);

  const { mutate: updateMutate, isLoading } = useMutation(postEreportServices, {
    onSuccess: () => {
      setNote(method.getValues('note'));
      setIsOpen(true);
      setIsSubmitted(true);
      method.reset();
    },
    onError: () => {
      setErrorModal({
        title: 'Thất bại',
        open: true,
        message: 'Gửi thất bại! Vui lòng thử lại!',
      });
    },
    onSettled: () => {
      setIsModalOpen(false);
    }
  });

  const handleSubmit = async (data: ReportFormType, status: number) => {
    if (token && key) {
      if (data.file) {
        const blob = await fetch(data?.file).then((res) => res.blob());
        const file = new File([blob], `signature-${data.file.length}.jpeg`, { type: 'image/jpeg' });
        updateMutate({
          token,
          key,
          status,
          note: data.note,
          file,
        });
      } else {
        updateMutate({
          token,
          key,
          status,
          note: data.note,
        });
      }
    }
  };

  const handleClick = (statusNumber: number) => {
    if (statusNumber !== STATUS.NOT_APPROVE && !watchFile) {
      setFileError('Chưa có chữ ký !');
      return;
    }
    if (fileError) setFileError('');
    setReportStatus(statusNumber);
    setIsModalOpen(true);
  };

  // const handleClickImage = () => {
  //   if (imageList && imageList?.length > 0) {
  //     setIsOpenImgs(true);
  //   } else {
  //     setErrorModal({
  //       title: 'Thông báo',
  //       open: true,
  //       message: 'Không có hình ảnh để hiển thị',
  //     });
  //   }
  // };

  useEffect(() => {
    if (eReportRes) {
      setNote(eReportRes?.approve_note);
      setReportStatus(eReportRes?.approve_status || 0);
      setIsSubmitted(!eReportRes.can_approve || !eReportRes.key_valid);
    }
  }, [eReportRes]);

  if (!token) {
    return (
      <div className="p-home_invalid">
        <Heading type="h2" modifiers={['700', 'center']}>
          Đường dẫn không hợp lệ, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  if (isNotFound) {
    return (
      <div className="p-home_invalid">
        <Heading type="h2" modifiers={['700', 'center']}>
          Không tìm thấy báo cáo, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  return (
    <PageLayout headerProps={{ title: 'BIÊN BẢN NGHIỆM THU ACTIVATION' }}>
      <FormProvider {...method}>
        <form noValidate>
          {(isFetching) && (
            <div className="p-home_loading">
              <Loading isShow variant="fullScreen" />
            </div>
          )}
          <div className="p-home">
            <div className="p-home_section">
              <InfoCard dataInfoCard={infoCard} />
            </div>
            <div className="p-home_section">
              <SectionTitle title="Kết quả thực hiện" />
              {/* <div className="p-home_mt">
                <Table headerTable={dataHeader} dataTable={dataTable} />
              </div> */}
            </div>
            <div className="p-home_section">
              <ProcessTitle
                title="Doanh số"
              />
              <div className="p-home_mt">
                <Table headerTable={dataHeaderReportKPITable} dataTable={dataTableKPI} />
              </div>
              <div className="p-home_mt">
                <ProcessTitle
                  title="Quà tặng"
                />
              </div>
              <div className="p-home_mt">
                <Table headerTable={dataPopup.header} dataTable={dataPopup.data} />
                {dataPopup?.comment && (
                  <div className="t-popupTable_note">
                    <Text type="span" modifiers={['eerieBlack', '14x20', '600']}>Ghi chú: </Text>
                    <Text type="span" modifiers={['eerieBlack', '14x20']}>{dataPopup?.comment}</Text>
                  </div>
                )}
              </div>
              <div className="p-home_mt">
                <ProcessTitle
                  title="Hình ảnh biên bản nghiệm thu"
                />
              </div>
              <div className="p-home_mt">
                <div className="p-home_listImage">
                  {eReportRes?.images.map((item, idx) => (
                    <div
                      className="p-home_listImage-item"
                      key={`image-item-${String(idx)}`}
                      onClick={() => {
                        setActiveIdx(idx);
                        setIsOpenImgs(true);
                      }}
                    >
                      <Image
                        imgSrc={item}
                        alt={`image-item-${String(idx)}`}
                        size="cover"
                        ratio="1x1"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {(note && isSubmitted) && (
              <div className="p-home_section">
                <div className="p-home_mt">
                  <Text modifiers={['16x24', 'eerieBlack', '600']}>
                    {reportStatus === STATUS.APPROVE ? 'Nhận xét về kết quả thực hiện: ' : 'Lý do từ chối :'}
                  </Text>
                  <Text modifiers={['14x20', 'eerieBlack', '500']}>
                    {note}
                  </Text>
                </div>
              </div>
            )}
            {!isSubmitted ? (
              <>
                <div className="p-home_section">
                  <SectionTitle title="Ký xác nhận" />
                  <div className="p-home_mt">
                    <Controller
                      name="file"
                      render={({ field: { onChange } }) => (
                        <Signature
                          handleCompleted={(file) => {
                            onChange(file);
                            setFileError('');
                          }}
                          error={fileError}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="p-home_section">
                  <div className="p-home_btnGroup">
                    <div className="p-home_btnGroup-item">
                      <Button
                        modifiers={['seashell']}
                        iconName="dismiss"
                        onClick={() => handleClick(STATUS.NOT_APPROVE)}
                      >
                        <Text modifiers={['redOrange', '16x24', '600', 'center']}>
                          Từ chối
                        </Text>
                      </Button>
                    </div>
                    <div className="p-home_btnGroup-item">
                      <Button
                        modifiers={['green']}
                        iconName="checkmark"
                        onClick={() => handleClick(STATUS.APPROVE)}
                      >
                        <Text modifiers={['white', '16x24', '600', 'center']}>
                          Duyệt
                        </Text>
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="p-home_section">
                {reportStatus === STATUS.APPROVE && (
                  <Text modifiers={['laSalleGreen', '16x24', '600', 'center', 'capitalize']}>
                    Đã duyệt
                  </Text>
                )}
                {reportStatus === STATUS.NOT_APPROVE && (
                  <Text modifiers={['redOrange', '16x24', '600', 'center', 'capitalize']}>
                    Từ chối
                  </Text>
                )}
                {reportStatus === STATUS.NOT_APPROVE_YET && (
                  <Text modifiers={['stormcloud', '16x24', '600', 'center', 'capitalize']}>
                    Chưa duyệt
                  </Text>
                )}
              </div>
            )}
          </div>
          <div className="p-home_popup">
            <PopupTable
              isOpen={!!popupType}
              handleClose={() => setPopupType('')}
              headerTable={dataPopup.header}
              dataTable={dataPopup.data}
              note={dataPopup?.comment}
            />
          </div>
          <div className="p-home_popup_images">
            <PopupImage
              open={isOpenImgs}
              handleClose={() => setIsOpenImgs(false)}
              listImage={eReportRes?.images || []}
              activeIdx={activeIdx}
            />
          </div>
          <NotifyModal
            isOpen={isOpen}
            title="Gửi thành công!"
            submitText="Đóng"
            handleSubmit={() => setIsOpen(false)}
            smallTitle
          />
          <NotifyModal
            isError
            isOpen={errorModal.open}
            title={errorModal.title}
            desc={errorModal.message || 'Đã có lỗi xảy ra. Vui lòng thử lại!'}
            submitText="Đóng"
            handleSubmit={() => setErrorModal({
              title: '',
              open: false,
              message: '',
            })}
          />
          <CustomModal
            showIconClose
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
          >
            <div className="p-home_section p-home_mt">
              <Controller
                name="note"
                rules={{ required: reportStatus === STATUS.NOT_APPROVE && 'Xin hãy nhập lý do' }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextArea
                    rows={4}
                    value={value}
                    placeholder={reportStatus === STATUS.APPROVE ? 'Nhập nhận xét về kết quả thực hiện' : 'Nhập Lý do'}
                    label={reportStatus === STATUS.APPROVE ? 'Nhận xét' : 'Lý do từ chối'}
                    id="text"
                    handleOnchange={onChange}
                    error={error?.message}
                  />
                )}
              />
            </div>
            <div className="p-home_btnGroup p-home_mt">
              <div className="p-home_btnGroup-item">
                <Button
                  modifiers={['primary']}
                  onClick={
                    () => method.handleSubmit(
                      (data) => handleSubmit(data, reportStatus)
                    )()
                  }
                  loading={isLoading}
                >
                  <Text modifiers={['white', '16x24', '600', 'center']}>
                    Gửi
                  </Text>
                </Button>
              </div>
            </div>
          </CustomModal>
        </form>
      </FormProvider>
    </PageLayout>
  );
};
export default Home;
